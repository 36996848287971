import React from 'react';
import { Link } from 'react-router-dom';
import Bio from '../../components/Bio/Bio';
import styles from './Home.module.scss';

interface IHomeProps {}

const Home: React.FC<IHomeProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.home}>
            <div className={styles.title}>
                <h1>Welcome</h1>
            </div>
            <Bio />
            <br />
            <Link to="/about-me">
                <h3>Read more about me &gt;</h3>
            </Link>
        </div>
    );
};

export default Home;
