import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

function useQuery() {
    const location = useLocation();
    return useMemo(() => {
        const params = new URLSearchParams(location.search);
        const paramsObj: Record<string, string | undefined> = {};
        const iterator = params.entries();
        let item = iterator.next();
        while (!item.done) {
            paramsObj[item.value[0]] = item.value[1];
            item = iterator.next();
        }
        return paramsObj;
    }, [location.search]);
}

export default useQuery;
