import React from 'react';
import AboutMe from '../../components/AboutMe/AboutMe';
import Bio from '../../components/Bio/Bio';
import ExternalLinks from '../../components/ExternalLinks/ExternalLinks';
import styles from './AboutMePage.module.scss';

interface IAboutMePageProps {}

const AboutMePage: React.FC<IAboutMePageProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1>About Me</h1>
            </div>
            <ExternalLinks />
            <AboutMe />
        </div>
    );
};

export default AboutMePage;
