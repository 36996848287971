import React, { useState } from 'react';
import styles from './Nav.module.scss';
import '../../common/logos.scss';
import { Link, useLocation } from 'react-router-dom';

const PersonalInfo: React.FC = () => {
    const [expanded, setExpanded] = useState(false);

    const location = useLocation();

    return (
        <div className={`${styles.container} ${expanded ? styles.expanded : ''}`}>
            <button className={styles.headerButton} onClick={() => setExpanded(!expanded)}>
                <h2>Navigation</h2>
                <h2 className={styles.indicator}>&gt;</h2>
            </button>
            <div className={styles.links}>
                <Link to="/" className={location.pathname === '/' ? styles.current : ''}>
                    Home
                </Link>
                <Link
                    to="/about-me"
                    className={location.pathname === '/about-me' ? styles.current : ''}
                >
                    About Me
                </Link>
                <Link
                    to="/projects"
                    className={
                        location.pathname === '/projects' || location.pathname.startsWith('/page')
                            ? styles.current
                            : ''
                    }
                >
                    Projects
                </Link>
                <Link
                    to="/skills"
                    className={location.pathname === '/skills' ? styles.current : ''}
                >
                    Skills
                </Link>
                <Link
                    to="/academic"
                    className={location.pathname === '/academic' ? styles.current : ''}
                >
                    Academic Experience
                </Link>
                <Link
                    to="/professional"
                    className={location.pathname === '/professional' ? styles.current : ''}
                >
                    Professional Experience
                </Link>
            </div>
        </div>
    );
};

export default PersonalInfo;
