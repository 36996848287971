import { ItemFormat } from '../common/data_types';
import mechatronicsIrobot from '../img/mechatronicsIrobot.jpg';

const mechatronics2Data: ItemFormat = {
    name: 'Mechatronics 2',
    date: '2019',
    description: '',
    img: mechatronicsIrobot,
    contain: false,
    parts: [
        {
            variant: 'image',
            src: mechatronicsIrobot,
        },
        {
            variant: 'text',
            text: `In the second half of 2017, I took the elective Mechatronics 2, which applied
            learnings from previous subjects to real world applications. Working with a
            team, I developed a control algorithm for a robot that could navigate a
            maze-like environment to achieve a set of simulated objectives.`,
        },
    ],
    externalLink: null,
    tags: ['Embedded', 'Academic'],
};

export default mechatronics2Data;
