import { ItemFormat } from '../common/data_types';
import bitalino from '../img/bitalino.jpg';

const professionalStudioBData: ItemFormat = {
    name: 'Professional Studio B',
    date: '2020',
    description: '',
    img: bitalino,
    contain: false,
    parts: [
        {
            variant: 'image',
            src: bitalino,
        },
        {
            variant: 'text',
            text: `In Professional Studio B, during the second half of 2020, I lead a group to
            develop a prototype for a health tracking system, integrating hardware and
            software.`,
        },
        {
            variant: 'text',
            text: `An array of BITalino sensors sent raw data to a cloud database, which was then
            interpreted and displayed on a live web dashboard.`,
        },
    ],
    externalLink: null,
    tags: ['Web', 'Embedded', 'Academic'],
};

export default professionalStudioBData;
