import React from 'react';
import { Link } from 'react-router-dom';
import { ItemFormat } from '../../common/data_types';
import { Tag } from './TagAdder/TagAdder';
import styles from './TileList.module.scss';

interface TileProps {
    item: ItemFormat & { key: number };
    activeTags: Tag[];
    from?: string;
}

const Tile: React.FC<TileProps> = (props) => {
    const { item, activeTags, from } = props;

    return (
        <Link
            to={`/page/${item.key}${from ? `?from=${from}` : ''}`}
            key={item.name}
            href={item.externalLink || undefined}
            className={styles['tile-item-wrapper']}
        >
            <div className={styles['tile-item']}>
                <div className={styles['tile-item-sizer']}>
                    <div className={styles.thumbnail}>
                        <div
                            className={item.contain ? styles.contain : ''}
                            style={{ backgroundImage: `url(${item.img})` }}
                        ></div>
                    </div>
                    <div className={styles.details}>
                        {/* <span>{item.date}</span> */}
                        <ul className={styles['tag-list']}>
                            {item.tags.map((tag) => (
                                <li
                                    key={tag}
                                    className={
                                        activeTags.find((activeTag) => activeTag.value === tag)
                                            ? styles.active
                                            : styles.inactive
                                    }
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                        <h3>{item.name}</h3>
                        {/* {item.description} */}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Tile;
