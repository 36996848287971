import React from 'react';
import './App.scss';
import Page from './views/Page/Page';

import { Route, Switch } from 'react-router-dom';
import Home from './views/Home/Home';
import PersonalProjects from './views/PersonalProjects/PersonalProjects';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import Nav from './components/Nav/Nav';
import AboutMePage from './views/AboutMePage/AboutMePage';
import Academic from './views/Academic/Academic';
import Professional from './views/Professional/Professional';
import Skills from './views/Skills/Skills';

const App: React.FC = () => {
    return (
        <div className="App">
            <PersonalInfo />
            <Nav />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about-me" component={AboutMePage} />
                <Route exact path="/projects" component={PersonalProjects} />
                <Route exact path="/academic" component={Academic} />
                <Route exact path="/professional" component={Professional} />
                <Route exact path="/skills" component={Skills} />
                <Route path="/page/:pageId" component={Page} />
            </Switch>
        </div>
    );
};

export default App;
