import React from 'react';
import styles from './Page.module.scss';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import items from '../../data';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
    TextPartType,
    ImagePartType,
    CodePartType,
    SideBySidePartType,
} from '../../common/data_types';
import Github from '../../img/logos/small-GitHub-Mark.svg';
import useQuery from '../../common/useQuery';

interface MatchParams {
    pageId: string;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

interface PartProps<T> {
    part: T;
}

const TextPart: React.FC<PartProps<TextPartType>> = ({ part }) => {
    return <div className={`${styles.part} ${styles.text}`}>{part.text}</div>;
};

const ImagePart: React.FC<PartProps<ImagePartType>> = ({ part }) => {
    return (
        <div className={`${styles.part} ${styles.image}`}>
            <img className={``} src={part.src} alt="" />
            {part.caption}
        </div>
    );
};

const CodePart: React.FC<PartProps<CodePartType>> = ({ part }) => {
    return (
        <SyntaxHighlighter language={part.language} showLineNumbers style={style}>
            {part.text}
        </SyntaxHighlighter>
    );
};

const SideBySidePart: React.FC<PartProps<SideBySidePartType>> = ({ part }) => {
    const LeftType = partMapping[part.left.variant];
    const RightType = partMapping[part.right.variant];
    return (
        <div className={`${styles.part} ${styles.side}`}>
            <div className={styles['side-left']}>
                <LeftType part={part.left} />
            </div>
            <div className={styles['side-left']}>
                <RightType part={part.right} />
            </div>
        </div>
    );
};

type Mapping = {
    [key: string]: any;
};

const partMapping: Mapping = {
    text: TextPart,
    image: ImagePart,
    code: CodePart,
    side: SideBySidePart,
};

const Page: React.FC<PageProps> = ({ match }) => {
    const item = items[Number(match.params.pageId)];

    const { from } = useQuery();

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <Link to={from || '/projects'} className={styles.back}>
                        &lt; Back
                    </Link>
                    <h1>{item.name}</h1>
                </div>
                {item.externalLink && (
                    <a
                        className={styles['external-link']}
                        href={item.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        // onClick={() => captureOutboundLink(url)}
                    >
                        <img className={styles.logo} src={Github} alt="github logo" />
                        {item.externalLink}
                    </a>
                )}
                <div className={styles.body}>
                    {item.parts.length ? (
                        item.parts.map((part) => {
                            const Type = partMapping[part.variant];
                            return <Type part={part} />;
                        })
                    ) : (
                        <div className={styles.empty}>
                            This page hasn't been written yet. Check back later to see if it has.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Page;
