import React from 'react';
import styles from './Professional.module.scss';

const Professional: React.VFC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1>Professional Experience</h1>
            </div>
            <div className={styles.content}>
                <h2>Wattcost</h2>
                <h3>Internship</h3>
                As part of my Diploma of Professional Practice for my degree, I have to undertake
                two 6-month internships in the field of software engineering. After some searching
                in early 2018, I found an ad for a startup called Wattcost through the university
                careers board. After their thorough hiring process, they were impressed with my
                experience and skills, offering me the position of Computer Science Intern.
                <br />
                <br />
                In this role, I was responsible for the development of the internal dashboard for
                administration of the platform. I built on top of their existing website using React
                which I had used previously a fair amount, and the backend API in Python, which I’d
                dabbled with a little. I was able to greatly grow my skills in these two languages,
                and requirements analysis when designing pages. I also learned about Agile
                development practices and how to apply them to projects.
                <br />
                <h3>Junior Software Engineer</h3>
                When my internship finished, I was offered the position of Junior Software Engineer
                at Wattcost, which I accepted. Initially I continued my work on the internal
                dashboard, but started diversifying and working on different parts of the platform.
                <br />
                <br />I worked at Wattcost for a further 1.5 years until the start of 2020, during
                which I worked on a large variety of tasks. The range of responsibilities kept me
                constantly learning and grew me greatly as an engineer.
                <h4>Major Tasks</h4>
                <ul>
                    <li>
                        Responsible for the full-stack design and development of the cross-platform
                        mobile app and related web services using the AngularJS framework and Python
                        with PostgreSQL.
                    </li>
                    <li>
                        Development of the internal administration dashboard with the ReactJS
                        framework, and the backend in Python and PostgreSQL.
                    </li>
                    <li>
                        Created deployment scripts for Wattcost services to AWS using Ansible and
                        Python, and deployment scripts for the mobile app for both Android and iOS,
                        allowing for automated deployment of the entire platform.
                    </li>
                    <li>
                        Responsible for maintaining and updating the company website with timeline
                        updates and monthly blog posts.
                    </li>
                </ul>
                <h2>UTS Rapido</h2>
                <h3>Internship</h3>
                In late 2020, I found my second internship with UTS Rapido, a technology development
                unit within UTS delivering hardware and software solutions to organisations. My
                significant experience in React web development was key to getting me the position.
                <br />
                <br />
                My first project was to update the user interface for a previously delivered web
                project, which was getting a revamp. My speed in delivering results greatly
                impressed my supervisor and manager, enough for them to assign me to a mobile app
                project in the time I now had free. Through this I learned C# and Xamarin for iOS
                development, which beat my expectations of working on web projects the whole
                internship.
                <br />
                <br />
                Something I learned from this was the significantly lower expectations my supervisor
                had for interns than I had for myself, which surprised me quite a lot. Through this
                first project I was able to prove my mettle as more than a standard intern.
                <br />
                <br />
                Since then, I have started one brand new project, using the NestJS framework for API
                development and a React frontend. I am also working on a project with a C# ASP.NET
                backend and API, which uses a different pattern to previous things I've worked on,
                which I'm enjoying learning and practicing with.
            </div>
        </div>
    );
};

export default Professional;
