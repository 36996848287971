import React from 'react';
import { Link } from 'react-router-dom';
import { ItemFormat } from '../../common/data_types';
import AboutMe from '../../components/AboutMe/AboutMe';
import Tile from '../../components/TileList/Tile';
import items from '../../data';
import embeddedSoftwareData from '../../pages/embeddedSoftware';
import mechatronics2Data from '../../pages/mechatronics2';
import neuralNetworksData from '../../pages/neuralNetworks';
import professionalStudioBData from '../../pages/professionalStudioB';
import styles from './Academic.module.scss';

interface SubjectProps {
    title: string;
    item: ItemFormat;
}

const Subject: React.FC<SubjectProps> = ({ item, title, children }) => {
    const key = items.findIndex((v) => v === item);
    return (
        <div className={styles.subject}>
            <h4>{title}</h4>
            <div className={styles.subjectContent}>
                <div className={styles.tile}>
                    <Tile
                        item={{
                            ...item,
                            key,
                        }}
                        activeTags={[]}
                        from="/academic"
                    />
                </div>
                <div className={styles.info}>
                    {children}
                    <br />
                    <br />
                    <Link to={`/page/${key}?from=/academic`}>
                        <strong>Read more &gt;</strong>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const Academic: React.VFC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1>Academic Experience &amp; Projects</h1>
            </div>
            <div className={styles.content}>
                I'm close to completing a Bachelor of ICT Engineering, majoring in Software
                Engineering at the University of Technology Sydney, on track to graduate mid-2021.
                Below I've outlined some of the subjects I enjoyed the most, which were largely
                those with interesting and engaging major assessments.
                <br />
                <br />
                <h3>Notable University Subjects</h3>
                <Subject item={embeddedSoftwareData} title="Embedded Software">
                    I took this subject in Autumn 2019, and although it was a very difficult
                    subject, I thoroughly enjoyed it. It taught me how to develop for an embedded
                    system from scratch, and work collaboratively with a peer group to solve
                    problems.
                </Subject>
                <Subject item={professionalStudioBData} title="Professional Studio B">
                    In Professional Studio B, during the second half of 2020, I lead a group to
                    develop a prototype for a health tracking system, integrating hardware and
                    software.
                    <br />
                    An array of BITalino sensors sent raw data to a cloud database, which was then
                    interpreted and displayed on a live web dashboard.
                </Subject>
                <Subject item={neuralNetworksData} title="Neural Networks and Fuzzy Logic">
                    At the start of 2019, I used one of my electives to take the subject Neural
                    Networks and Fuzzy Logic. In it I learned the fundamentals of neural networks,
                    and worked with a partner to utilise it in a project.
                    <br />
                    Our end product was a program that could classify hand gestures from a Leap
                    Motion Controller, to allow control of a hobby robotic arm in real time.
                    {/* <ul>
                        <li>
                            Trained a Leap Motion hand sensor to classify hand gestures, which we
                            applied to controlling a robotic arm in realtime.
                        </li>
                        <li>
                            Intention is for someone with limited use of only one hand could control
                            the robotic arm to perform tasks they otherwise couldn’t.
                        </li>
                        <li>
                            We were very proud that we were able to apply machine learning to an
                            actual scenario, and not just “there is a stop sign in this image”.
                        </li>
                        <li>Video?</li>
                    </ul> */}
                </Subject>
                <Subject item={mechatronics2Data} title="Mechatronics 2">
                    In the second half of 2017, I took the elective Mechatronics 2, which applied
                    learnings from previous subjects to real world applications. Working with a
                    team, I developed a control algorithm for a robot that could navigate a
                    maze-like environment to achieve a set of simulated objectives.
                    {/* <ul>
                        <li>Diagrams for robot control</li>
                        <li>Include video of complete final run</li>
                    </ul> */}
                </Subject>
            </div>
        </div>
    );
};

export default Academic;
