import { ItemFormat } from '../common/data_types';
import robotArm from '../img/robotArmLeapMotion.png';

const neuralNetworksData: ItemFormat = {
    name: 'Neural Networks & Fuzzy Logic',
    date: '2019',
    description: '',
    img: robotArm,
    contain: true,
    parts: [
        {
            variant: 'image',
            src: robotArm,
        },
        {
            variant: 'text',
            text: `At the start of 2019, I used one of my electives to take the subject Neural
            Networks and Fuzzy Logic. In it I learned the fundamentals of neural networks,
            and worked with a partner to utilise it in a project.`,
        },
        {
            variant: 'text',
            text: `Our end product was a program that could classify hand gestures from a Leap
            Motion Controller, to allow control of a hobby robotic arm in real time.`,
        },
    ],
    externalLink: null,
    tags: ['Machine Learning', 'Embedded', 'Academic'],
};

export default neuralNetworksData;
