import { ItemFormat } from '../common/data_types';
import kinetisTower from '../img/kinetisTower.png';

const embeddedSoftwareData: ItemFormat = {
    name: 'Embedded Software',
    date: '2019',
    description: '',
    img: kinetisTower,
    contain: true,
    parts: [
        {
            variant: 'image',
            src: kinetisTower,
        },
        {
            variant: 'text',
            text: `I took this subject in Autumn 2019, and although it was a very difficult
            subject, I thoroughly enjoyed it. It taught me how to develop for an embedded
            system from scratch, and work collaboratively with a peer group to solve
            problems.`,
        },
        {
            variant: 'text',
            text: `One of the most satisfying parts of the subject was learning how to
            read a 2000 page datasheet to find how to use a particular feature of
            the microprocessor. The way the subject made us build up a collection of
            hardware abstraction layers to then use in the final assessment was really
            great, and made sure we understood every step along the way.`,
        },
    ],
    externalLink: null,
    tags: ['Embedded', 'Academic'],
};

export default embeddedSoftwareData;
