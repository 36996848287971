import React from "react";
import TileList from "../../components/TileList/TileList";
import styles from './PersonalProjects.module.scss';

interface PersonalProjectsProps {
}

const PersonalProjects: React.FC<PersonalProjectsProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1>Assorted Projects</h1>
            </div>
            <TileList/>
        </div>
    );
};

export default PersonalProjects;
