import f from './img/iotControls.jpg';
import f2 from './img/prusa-slicer_2020-01-04_12-49-18.png';
import f3 from './img/ss2016-04-28at11_40_10.jpg';
import f4 from './img/ss2016-11-18at03_04_37.jpg';
import f5 from './img/BlockHop_2017-06-26_23-28-15.png';
import f6 from './img/firefox_2018-01-30_23-46-42.png';
import f7 from './img/solvespace_2018-02-21_23-48-32.png';
import f8 from './img/firefox_2018-04-08_18-07-45.png';
import f9 from './img/firefox_2020-02-27_22-13-24.png';
import discord from './img/Discord-Logo+Wordmark-Color.svg';
import gcodeExplainer from './img/G-code-Explainer.png';
import sunnySide from './img/SunnySide.png';
import fishBoom from './img/fishBoom.png';
import vulkan from './img/vulkan2.svg';
import laser from './img/Laser-symbol.svg';
import discordNodeMCU from './img/discord_NodeMCU.jpg';
import javaAdventure from './img/javaAdventure.png';
import caverLevel from './img/caverLevel.png';

import { ItemFormat } from './common/data_types';
import bearHuntData from './pages/bearHunt';
import embeddedSoftwareData from './pages/embeddedSoftware';
import professionalStudioBData from './pages/professionalStudioB';
import mechatronics2Data from './pages/mechatronics2';
import neuralNetworksData from './pages/neuralNetworks';

const items: Array<ItemFormat> = [
    bearHuntData,
    embeddedSoftwareData,
    professionalStudioBData,
    mechatronics2Data,
    neuralNetworksData,
    {
        name: '"Caver"',
        date: '2012',
        description: '',
        img: caverLevel,
        contain: false,
        parts: [
            {
                variant: 'image',
                src: caverLevel,
            },
            {
                variant: 'text',
                text:
                    'Caver was a game I made during high school to use a cave-generation algorithm I found online. You control a red square and have to collect the gold scattered throughout the caves.',
            },
        ],
        externalLink: null,
        tags: ['Games'],
    },
    {
        name: '"Adventure"',
        date: '2012',
        description: '',
        img: javaAdventure,
        contain: false,
        parts: [
            {
                variant: 'image',
                src: javaAdventure,
            },
            {
                variant: 'text',
                text: `Adventure was one of the earliest games I made during high school in Java.
                You control the player character and have to defeat monsters to gain money,
                then buy upgrades and defeat them faster.`,
            },
            {
                variant: 'text',
                text: `I believe the reason I stopped working on this was that everything in the game world was
                hand placed with coordinates, so was painstaking to do any sort of level design.`,
            },
        ],
        externalLink: null,
        tags: ['Games'],
    },
    {
        name: 'ESP32/8266 Discord Client',
        date: '8/1/2020',
        description: '',
        img: discordNodeMCU,
        contain: false,
        parts: [
            {
                variant: 'image',
                src: discordNodeMCU,
            },
            {
                variant: 'text',
                text: `This is a proof-of-concept project that implements the Discord WebSocket API gateway on an ESP8266 or ESP32 microcontroller.`,
            },
            {
                variant: 'text',
                text: `It was successful, and works pretty well within a few restrictions around memory usage. I never came up with a
                use-case for the project myself, but I've responded to a few requests from people setting it up`,
            },
        ],
        externalLink: 'https://github.com/Cimera42/esp-discord-client',
        tags: ['Embedded', 'Web', 'Internet of Things'],
    },
    {
        name: 'BlockHop',
        date: '8/1/2020',
        description: '',
        img: f5,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/BlockHop',
        tags: ['Graphics', 'Games'],
    },
    {
        name: 'Platfo',
        date: '8/1/2020',
        description: '',
        img: f3,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/Platfo',
        tags: ['Graphics', 'Games'],
    },
    {
        name: 'Vulkan',
        date: '8/1/2020',
        description: '',
        img: vulkan,
        contain: true,
        parts: [],
        externalLink: 'https://github.com/Cimera42/Vulkanite',
        tags: ['Graphics'],
    },
    {
        name: 'IoT Controls',
        date: '8/1/2020',
        description: '',
        img: f,
        contain: false,
        parts: [
            //             {
            //                 variant: 'text',
            //                 text: "Far out in the uncharted backwaters of the unfashionable end of the western spiral arm of the Galaxy lies a small unregarded yellow sun. Orbiting this at a distance of roughly ninety-­‐two million miles is an utterly insignificant little blue green planet whose ape-­‐descended life forms are so amazingly primitive that they still think digital watches are a pretty neat idea. This planet hasʹor rather hadʹa problem, which was this: most of the people on it were unhappy for pretty much of the time. Many solutions were suggested for this problem, but most of these were largely concerned with the movements of small green pieces of paper, which is odd because on the whole it wasn't the small green pieces of paper that were unhappy. And so the problem remained; lots of the people were mean, and most of them were miserable, even the ones with digital watches. Many were increasingly of the opinion that they'd all made a big mistake in coming down from the trees in the first place. And some said that even the trees had been a bad move, and that no one should ever have left the oceans. And then, one Thursday, nearly two thousand years afterone man had been nailed to a tree for saying how great it would be to be nice to people for a change, one girl sitting on her own in a small cafe in Rickmansworth suddenly realized what it was that had been going wrong all this time, and she finally knew how the world could be made a good and happy place. This time it was right, it would work, and no one would have to get nailed to anything. Sadly, however, before she could get to a phone to tell anyone about it, a terribly stupid catastrophe occurred, and the idea was lost forever. This is not her story.",
            //             },
            //             {
            //                 variant: 'code',
            //                 language: 'javascript',
            //                 text:
            // `import React from 'react';
            // export default {
            //     type: 'code',
            //     language: 'javascript',
            //     text: "import React from 'react'",
            // },`,
            //             },
            //             {
            //                 variant: 'image',
            //                 src: f,
            //             },
            //             {
            //                 variant: 'side',
            //                 left: {
            //                     variant: 'image',
            //                     src: f2,
            //                 },
            //                 right: {
            //                     variant: 'code',
            //                     language: 'html',
            //                     text:
            // `<html>
            //     <body>
            //         <img src=${f2}/>
            //     </body>
            // </html>`,
            //                 },
            //             },
            //             {
            //                 variant: 'text',
            //                 text: "But it is the story of that terrible stupid catastrophe and some of its consequences. It is also the story of a book, a book called The Hitch Hiker's Guide to the Galaxyʹnot an Earth book, never published on Earth, and until the terrible catastrophe occurred, never seen or heard of by any Earthman. Nevertheless, a wholly remarkable book. In fact it was probably the most remarkable book ever to come out of the great publishing houses of Ursa Minorʹof which no Earthman had ever heard either. Not only is it a wholly remarkable book, it is also a highly successful oneʹmore popular than the Celestial Home Care Omnibus, better selling than Fifty More Things to do in Zero Gravity, and more controversial than Oolon Colluphid's trilogy of philosophical blockbusters Where God Went Wrong, Some More of God's Greatest Mistakes and Who is this God Person Anyway? In many of the more relaxed civilizations on the Outer Eastern Rim ofthe Galaxy, the Hitch Hiker's Guide has already supplanted the great Encyclopedia Galactica as the standard repository of all knowledge and wisdom, for though it has many omissions and contains much that is apocryphal, or at least wildly inaccurate, it scores over the older, more pedestrian work in two important respects. First, it is slightly cheaper; and secondly it has the words DON'T PANIC inscribed in large friendly letters on its cover. But the story of this terrible, stupid Thursday, the story of its extraordinary consequences, and the story of how these consequences are inextricably intertwined with this remarkable book begins very simply. It begins with a house.",
            //             }
        ],
        externalLink: 'https://github.com/Cimera42/mqtt-controls',
        tags: ['3D Printing', 'Internet of Things', 'Embedded'],
    },
    // {
    //     name: 'Clamp',
    //     date: '8/1/2020',
    //     description: 'Long ass description text goes here so I can explain what the project is. Apparently this text is not long enough yet so I need to make it even longer so that it actually wraps onto a new line so i can see what it looks lke when such an event occurs, in which case i will evaluate what has occurred and make it work unto the way i wish it to',
    //     img: f2,
    //     contain: false,
    //     parts: [],
    //     externalLink: 'https://github.com/Cimera42/Platfo',
    //     tags: [],
    // },
    {
        name: 'Stellar Visualiser',
        date: '8/1/2020',
        description: '',
        img: f6,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/StellarVisualiser',
        tags: ['Crypto', 'Graphics', 'Web'],
    },
    {
        name: 'Cryptosounds',
        date: '8/1/2020',
        description: '',
        img: f9,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/cryptosounds',
        tags: ['Crypto', 'Web'],
    },
    {
        name: 'G-code Explainer',
        date: '8/1/2020',
        description: '',
        img: gcodeExplainer,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/gcode-explainer',
        tags: ['3D Printing', 'Web'],
    },
    {
        name: 'SunnySide',
        date: '8/1/2020',
        description: '',
        img: sunnySide,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/SunnySide',
        tags: ['Web'],
    },
    {
        name: 'FishBoom',
        date: '8/1/2020',
        description: '',
        img: fishBoom,
        contain: false,
        parts: [],
        externalLink: 'https://github.com/Cimera42/fish-boom',
        tags: ['Graphics', 'Web', 'Games'],
    },
    {
        name: 'Discord Bot',
        date: '8/1/2020',
        description: '',
        img: discord,
        contain: true,
        parts: [],
        externalLink: 'https://github.com/Cimera42/DiscordBot',
        tags: ['Web'],
    },
    {
        name: 'Laser Pointer',
        date: '8/1/2020',
        description: '',
        img: laser,
        contain: true,
        parts: [],
        externalLink: 'https://github.com/Cimera42/laser-turret',
        tags: ['3D Printing', 'Embedded'],
    },
    {
        name: 'Fan Holder',
        date: '8/1/2020',
        // description: 'One of the first models for my 3d printer I made myself. Works, but no way to secure the fan or duct so they tend to fall off quite frequently',
        description: '',
        img: f7,
        contain: false,
        parts: [],
        externalLink: null,
        tags: ['3D Printing'],
    },
    {
        name: 'hackmud Scripts',
        date: '8/1/2020',
        description: '',
        img: f4,
        contain: true,
        parts: [],
        externalLink: null,
        tags: ['Games'],
    },
    {
        name: 'Custom PCB',
        date: '8/1/2020',
        description: '',
        img: f8,
        contain: true,
        parts: [],
        externalLink: null,
        tags: ['Embedded'],
    },
];

export default items;
