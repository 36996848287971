import React from "react";
import styles from './PersonalInfo.module.scss';
import '../../common/logos.scss';

const captureOutboundLink = function(url: string) {
}

interface LogoLinkProps {
    url: string,
    logoName: string,
};

const LogoLink: React.FC<LogoLinkProps> = ({url, logoName}) => (
    <a
        className={`${styles.logo} ${logoName}-logo`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => captureOutboundLink(url)}
    > </a>
);

const PersonalInfo: React.FC = () => {
    return (
        <div
            className={styles.profile}
        >
            <div className={styles.name}>
                <h1>Tim Porritt</h1>
                <div className={styles.links}>
                    <LogoLink
                        logoName="github"
                        url="https://github.com/Cimera42"
                    />
                    <LogoLink
                        logoName="linkedin"
                        url="https://www.linkedin.com/in/tim-porritt/"
                    />
                    <LogoLink
                        logoName="email"
                        url="mailto:tim.m.porritt@gmail.com?subject=Website Contact"
                    />
                </div>
            </div>
            <div className={styles.photo} />
        </div>
    );
};

export default PersonalInfo;
