import { Link } from 'react-router-dom';
import styles from './AboutMe.module.scss';

interface AboutMeProps {}

const AboutMe: React.FC<AboutMeProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.aboutMe}>
            I’m Tim Porritt, an engineer close to completing my Bachelor of Engineering in ICT
            Software at the University of Technology Sydney. I started my software engineering
            journey in around year 8 of high school, when I decided I wanted to make mods for the
            game Minecraft. Through some research, I found it was made in Java, so looked up some
            tutorials and started learning.
            <br />
            <br />
            This evolved into various software topics, which I continued working on at home and at
            school. The decision of degree at university came down to a toss-up between mechatronics
            and software engineering, of which I chose software.
            <br />
            <br />
            I’ve worked on a large number of engineering projects ranging from purely software
            websites to 3d printing and modelling, to embedded software &amp; electronics. I’ve done
            these through university, my professional practice and in my personal work. Information
            on these can be found throughout this portfolio.
            <br />
            <br />
            <Link to="/projects">
                <h2 className={styles.projectsLink}>Go to Projects &gt;</h2>
            </Link>
            <br />
            <Link to="/skills">
                <h3 className={styles.projectsLink}>Go to Skills overview &gt;</h3>
            </Link>
            <br />
            <Link to="/academic">
                <h3 className={styles.projectsLink}>Go to Academic Experience &gt;</h3>
            </Link>
            <br />
            <Link to="/professional">
                <h3 className={styles.projectsLink}>Go to Professional Experience &gt;</h3>
            </Link>
            <br />
            <h3>Where do I see myself going</h3>
            After graduating in mid-2021, I see myself continuing working at UTS Rapido, where I am
            currently interning, for 2-3 years, then depending on circumstances either founding a
            startup or finding a position at another company with more potential for advancement or
            learning. Throughout this journey, I’ll endeavour to learn new technologies and develop
            my skills to keep up with industry trends and movement.
            <br />
        </div>
    );
};

export default AboutMe;
