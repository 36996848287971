import React, { useState } from "react";
import './TagAdder.scss';

const captureTagEvent = function(action: 'add' | 'remove', tagName: string) {
}

export interface Tag {
    id: number,
    value: string,
    active: boolean,
}

type TagAdderProps = {
    list: Array<Tag>,
    onUpdate: (tagList: Array<Tag>) => void
};

const TagAdder: React.FC<TagAdderProps> = ({list ,onUpdate}) => {
    // const [maxId, setMaxId] = useState(0);
    // const [inputValue, setInputValue] = useState('');

    // const addTag = (value: string) => {
    //     if(value && value.trim() !== '') {
    //         setTagList([
    //             {
    //                 id: maxId,
    //                 value,
    //                 active: true,
    //             },
    //             ...tagList
    //         ]);
    //         setMaxId(maxId + 1);
    //     }
    // };
    // const removeTag = (index: number) => {
    //     setTagList([
    //         ...tagList.slice(0, index),
    //         ...tagList.slice(index + 1),
    //     ]);
    // }
    const toggleTag = (index: number) => {
        const tag = list[index];
        const newActive = !tag.active;
        captureTagEvent(newActive ? 'add' : 'remove', tag.value);

        onUpdate([
            ...list.slice(0, index),
            {
                ...tag,
                active: newActive,
            },
            ...list.slice(index + 1),
        ]);
    }

    return (
        <div className="tag-adder">
            <h4>Tags</h4>
            {/* <form
                className="input"
                onSubmit={(ev) => {
                    addTag(inputValue);
                    ev.preventDefault();
                }}
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={(ev) => setInputValue(ev.target.value)}
                />
                <input
                    type="submit"
                    className="add-button"
                    value=""
                />
            </form> */}
            {!!list.length &&
                <ul>
                    {list.map(({id, value, active}, i) => (
                        <li
                            key={`${id}`}
                            className={active ? 'active' : 'inactive'}
                        >
                            <button
                                onClick={() => toggleTag(i)}
                            >
                                {value}
                            </button>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};

export default TagAdder;
