import styles from './Bio.module.scss';

interface IBioProps {}

const Bio: React.FC<IBioProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.bio}>
            Hi, I'm Tim Porritt, a Software Engineer studying at UTS, passionate about all things
            technology and engineering. I love developing projects with a purpose and solving
            challenging problems.
            <br />
            <br />
            Through this portfolio, you can find write-ups for projects I’ve worked on, background
            on my academic and professional experience. Although not exhaustive, it shows a good
            cross section of what I've done and the work I enjoy.
        </div>
    );
};

export default Bio;
