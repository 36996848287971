import React, { useState } from 'react';
import TagAdder, { Tag } from './TagAdder/TagAdder';
import styles from './TileList.module.scss';
import items from '../../data';
import { Link } from 'react-router-dom';
import Tile from './Tile';

const TileList: React.FC = () => {
    const [tagList, setTagList] = useState<Array<Tag>>([
        {
            id: 9,
            value: 'Academic',
            active: false,
        },
        {
            id: 6,
            value: 'Embedded',
            active: false,
        },
        {
            id: 7,
            value: 'Web',
            active: false,
        },
        {
            id: 1,
            value: 'Graphics',
            active: false,
        },
        {
            id: 3,
            value: 'Games',
            active: false,
        },
        {
            id: 4,
            value: '3D Printing',
            active: false,
        },
        {
            id: 5,
            value: 'Crypto',
            active: false,
        },
        {
            id: 8,
            value: 'Internet of Things',
            active: false,
        },
        {
            id: 2,
            value: 'Machine Learning',
            active: false,
        },
        {
            id: 9,
            value: 'Mobile Apps',
            active: false,
        },
    ]);

    const activeTags = tagList.filter((tag) => tag.active);

    return (
        <div>
            <TagAdder list={tagList} onUpdate={setTagList} />
            <div className={styles['tile-list']}>
                {items
                    .map((item, i) => ({ ...item, key: i }))
                    .filter(
                        (item) =>
                            activeTags.length === 0 ||
                            tagList.find(
                                (tag) => tag && tag.active && item.tags.includes(tag.value)
                            )
                    )
                    .map((item) => (
                        <Tile item={item} activeTags={activeTags} />
                    ))}
            </div>
        </div>
    );
};

export default TileList;
