import { ItemFormat } from '../common/data_types';
import bearHunt from '../img/bearHunt.jpg';
import bearHuntDead from '../img/deadBearHunt.png';

const bearHuntData: ItemFormat = {
    name: 'Bear Hunt',
    date: '1/5/2020',
    description: '',
    img: bearHunt,
    contain: false,
    parts: [
        {
            variant: 'image',
            src: bearHunt,
            caption: 'Main screen of the Bear Hunt app',
        },
        {
            variant: 'text',
            text:
                'Myself and my friends decided we wanted to group together and build some business ideas that we each had. The idea for our first endeavour came as courtesy of the COVID19 lockdown of 2020. People around the globe had started the practice of placing a teddy bear out the front of their house so that parents could take their kids for walks, spotting bears as they go.',
        },
        {
            variant: 'text',
            text:
                'We decided to build a mobile app which would allow parents to keep track of teddy bears they’d found, and share the locations with other users of the app. The idea was that parents could plan a route with their kids that they knew they would spot some bears.',
        },
        {
            variant: 'text',
            text:
                'We built it pretty rapidly, in around a month, utilising Expo/React Native for a cross-platform mobile app, and Firebase. We followed a very iterative process, first investigating the feasibility of various libraries, then building the most important part of the app, the map. This flowed into the process for adding a new bear to the map, then viewing a list of all the bears you have added, and all the bears you’ve found in the past.',
        },
        {
            variant: 'text',
            text:
                'It was a fantastic learning experience in mobile app development, and especially deployment. Despite having some experience with deployment to Google Play and the Apple App Store through my job at Wattcost, this was a whole different experience, creating it all from scratch. It was surprising to find how much was required of an app to be listed on each store, ranging from different sized screenshots, to an externally hosted privacy policy, to providing access to store staff for testing.',
        },
        {
            variant: 'text',
            text:
                'Ultimately, the app hit a multitude of snags around Google SDK licences and the app being aimed toward children. Only after about 6 months did any of these rear their head. The first significant one we encountered was that ads are not allowed to be targeted, as that requires collection of data, which is not allowed for children. We identified a fix and applied it, but in doing so must have triggered a re-review of the app, as a new issue around the Google Map SDK we used appeared out of nowhere. It turns out that there must be some data collection hard-baked into the SDK, as the licence states it is not allowed to be used in apps made for kids at all. In the end, this is what made us take the app offline, as all of us in the group were busy and didn’t see a trivial way to fix it. With lockdown restrictions easing here in Australia, we saw little need for the app and were fine, if disheartened, with taking the app down.',
        },
        {
            variant: 'image',
            src: bearHuntDead,
            caption: 'Screenshot of the rejected app update',
        },
    ],
    externalLink: null,
    tags: ['Mobile Apps'],
};

export default bearHuntData;
