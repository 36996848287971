import styles from './ExternalLinks.module.scss';
import '../../common/logos.scss';

interface ExternalLinksProps {}

interface LogoLinkProps {
    url: string;
    logoName: string;
}

const LogoLink: React.FC<LogoLinkProps> = ({ url, logoName, children }) => (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
        <div className={`${styles.logo} ${logoName}-logo`} />
        {children}
    </a>
);

const ExternalLinks: React.FC<ExternalLinksProps> = (props) => {
    const {} = props;

    return (
        <div className={styles.container}>
            <LogoLink logoName="document" url="/Resume_Tim_Porritt.pdf">
                Resume
            </LogoLink>
            <LogoLink logoName="github" url="https://github.com/Cimera42/">
                GitHub
            </LogoLink>
            <LogoLink logoName="linkedin" url="https://www.linkedin.com/in/tim-porritt/">
                LinkedIn
            </LogoLink>
            <LogoLink logoName="email" url="mailto:tim.m.porritt@gmail.com?subject=Website Contact">
                Email
            </LogoLink>
        </div>
    );
};

export default ExternalLinks;
