import React from 'react';
import styles from './Skills.module.scss';

const Skills: React.VFC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1>Skills</h1>
            </div>
            <div className={styles.content}>
                <h2>Technical Skills</h2>
                <h4>Programming Proficiency</h4>
                Proficiency in a large range of programming languages, frameworks and libraries for
                a large range of purposes. Gained through professional, academics and personal
                projects.
                <div className={styles.listContainer}>
                    <ul>
                        <li>JavaScript</li>
                        <li>TypeScript</li>
                        <li>Python</li>
                        <li>C</li>
                        <li>C++</li>
                    </ul>
                    <ul>
                        <li>C#</li>
                        <li>Java</li>
                        <li>PHP</li>
                        <li>SQL</li>
                        <li>NodeJS</li>
                    </ul>
                    <ul>
                        <li>React</li>
                        <li>AngularJS</li>
                        <li>OpenGL</li>
                        <li>ASP.NET</li>
                        <li>PostgreSQL</li>
                    </ul>
                </div>
                <h4>System Architecture Design</h4>
                Experience in designing system architectures from working at Wattcost assisting with
                the design of the microservices ecosystem. Additional formal learning through the
                subject Software Architecture at University.
                <h4>Professional Engineering Practices</h4>
                Experience following software engineering best practices such as source control and
                code reviews, as well as development of continuous integration, testing processes
                and automated deployment gained working at Wattcost.
                <h4>Cloud Service Providers</h4>
                Knowledge and experience in Amazon Web Services gained from research and deployment
                of Wattcost services and UTS Rapido projects.
                <br />
                Light usage of Heroku and DigitalOcean for hosting of personal projects.
                <br />
                <br />
                <h2>Core Skills</h2>
                <h4>Communication</h4>
                Comprehensive technical and multi-disciplinary communication, both written and
                spoken developed through professional work at Wattcost and UTS Rapido, including
                project documentation and discussion of project objectives.
                <br />
                <br />
                Communication skills with external clients matured during my internship at UTS
                Rapido, and the Software Development Studio at UTS.
                <br />
                <br />
                Fostered public speaking skills through numerous university presentations in
                subjects such as Engineering Practice Reflection 1, as well as team leadership
                roles.
                <h4>Leadership</h4>
                Experience in taking the leadership role during numerous university group projects,
                leading multi-disciplinary teams of student engineers. Effectively delegated tasks
                and resolved issues to efficiently deliver assessment projects.
                <br />
                <br />
                Although I did not initially take up leadership positions, I have found myself
                falling into those roles more and more as my university course has progressed. I
                attribute this to my strong communication and technical skills, allowing me to focus
                on leading the team.
                <h4>Teamwork</h4>
                Experience working as part of the multi-disciplinary team at Wattcost and UTS
                Rapido, interacting with software engineers, engineers in other fields, and
                non-engineers.
                <br />
                <br />
                Teamwork additionally developed through numerous group projects at university
                throughout the entire course.
            </div>
        </div>
    );
};

export default Skills;
